import App from "srs.sharedcomponents/lib/esm/App";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import {
  ApplicationInsights,
  IPageViewTelemetry,
} from "@microsoft/applicationinsights-web";
import { env } from "srs.sharedcomponents/lib/esm/api/env";
import { store } from "srs.sharedcomponents/lib/esm/redux/store";
import { useAppSelector } from "srs.sharedcomponents/lib/esm/redux/hooks";
import { setFooterLinks } from "srs.sharedcomponents/lib/esm/redux/slices/footerLinksSlice";
import { useDispatch } from "react-redux";

import { uuidv4 } from "srs.sharedcomponents/lib/esm/utils/helpers";

import axios, { AxiosResponse } from "axios";
import { i18nNamespaces as namespace } from 'srs.sharedcomponents/lib/esm/i18n/i18n'
import coreSrsPrimaryOverride from './i18n/coreSrsPrimaryOverride.json'
import { useTranslation } from 'react-i18next'
import RouterProvider from "srs.sharedcomponents/lib/esm/components/context/router/RouterProvider";
import routerConfig from "srs.sharedcomponents/lib/esm/components/config/routes";

interface IAppWrapper {
  appInsights: ApplicationInsights;
}

const AppWrapper = ({ appInsights }: IAppWrapper) => {
  const location = useLocation();
  const dispatch = useDispatch();

  const { i18n } = useTranslation()
	Object.entries(coreSrsPrimaryOverride).forEach(([locale, entity]) =>{
		Object.entries(entity).forEach(([key, value]) => {
			i18n.addResource(locale, namespace.coreSrsPrimary, key, value)
		})
	})

  const welcome = useAppSelector((state) => state.welcome);
  const footerLinks = useAppSelector((state) => state.footerLinks.links);

  useEffect(() => {
    const myRequestInterceptor = axios.interceptors.request.use((request) => {
      const { method, url, headers } = request;

      if (request.headers) {
        request.headers["X-Correlation-Id"] = uuidv4();
      }

      if (request) {
        appInsights.trackEvent({
          name: "API Request Toyota",
          properties: {
            ApplicationName: "toyota",
            RequestPath: url ?? "",
            Method: method ?? "",
            CorrelationId: headers ? headers["X-Correlation-Id"] : "",
          },
        });
      }

      return request;
    });

    return () => {
      axios.interceptors.request.eject(myRequestInterceptor);
    };
  });

  useEffect(() => {
    const myResponseInterceptor = axios.interceptors.response.use(
      (response: AxiosResponse) => {
        const { config, status, statusText } = response;
        const { method, url, headers } = config;

        if (config) {
          appInsights.trackEvent({
            name: "API Response Toyota",
            properties: {
              ApplicationName: "toyota",
              RequestPath: url ?? "",
              Method: method ?? "",
              CorrelationId: headers ? headers["X-Correlation-Id"] : "",
              StatusCode: status,
              StatusCodeText: statusText,
            },
          });
        }

        return response;
      },
      (error) => {
        // You can also log the error details in case of request failure
        appInsights.trackEvent({
          name: "API Error",
          properties: {
            message: error.message,
            // Additional properties as needed
          },
        });

        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.response.eject(myResponseInterceptor);
    };
  });

  useEffect(() => {
    const pageInfo: IPageViewTelemetry = {
      name: location?.pathname,
      uri: window?.location.href,
      isLoggedIn: store.getState().user?.user?.token ? true : false,
    };
    if (env.APPLICATIONINSIGHTS_CONNECTION_STRING) {
      appInsights.trackPageView(pageInfo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  useEffect(() => {
    const newFooterLinks = footerLinks.filter(
      (link) => link.title !== "Footer_HowToOrder"
    );
    const newLinks = [
      ...newFooterLinks,
      {
        id: "howToOrder",
        route: `${welcome.welcome?.cmsBaseUrl}/UserManagedItems/Documents/Global/Footer/en-US/How%20To%20Order.htm`,
        title: "Footer_HowToOrder",
        target: "_blank",
      },
    ];

    dispatch(setFooterLinks(newLinks));
  }, [welcome.welcome?.cmsBaseUrl]);

    return (    
    <RouterProvider routesConfig={routerConfig}>
        <App />
    </RouterProvider>
    )
};

export default AppWrapper;