import { globalTheme } from "srs.sharedcomponents/lib/esm/styles/config";

const toyotaThemeColor = "#333333";
export const customTheme = {
  ...globalTheme,
  colors: {
    ...globalTheme.colors,
    primary: toyotaThemeColor,
  },
};
